import { Partner } from "../partner.types";
import { Texts } from "./texts.types";

const VIVO: Texts = {
  home: {
    title: "Sua economia começa agora!",
    description:
      "Preencha seus dados para ver se é elegível e gerar sua proposta.",
  },
  selectYourDistributor: {
    title: "Não encontrei minha distribuidora",
    description:
      "Basta selecionar a sua distribuidora e manteremos você informado sobre a IVI na sua região!",
  },
  contact: {
    title: "Dados de Contato",
    description: "Faça seu cadastro na plataforma. É rapidinho!",
  },
  validation: {
    title: "Código de Validação",
    description: "Preencha com o código recebido para validar seu e-mail.",
  },
  holder: {
    title: "Dados do titular da conta",
    description: "Deve ser o mesmo CNPJ ou CPF da conta de luz.",
  },
  holderPF: {
    title: "Dados do titular da conta",
    description: "Preencha os dados do titular da conta de luz.",
  },
  holderPJ: {
    title: "Dados do titular da conta",
    description: "Preencha os dados do titular da conta de luz.",
  },
  installation: {
    title: "Dados da conta de luz",
    description:
      "Precisamos de uma fatura dos últimos 3 meses para analisarmos o perfil de consumo.",
  },
  confirmYourData: {
    title: "Confirmação",
    description:
      "Quase lá! Verifique os seus dados cadastrais e da conta de energia antes de seguir.",
  },
  contractOfAdhesion: {
    title: "Termo de adesão",
    description:
      "Leia o termo de adesão e efetue a assinatura para começar a economizar. IMPORTANTE: esse passo deve ser feito pelo representante legal da empresa.",
  },
  pdfErrorValidation: {
    title: "Solicitação de validação enviada!",
    description:
      "Sua fatura será analisada e, em breve, enviaremos um e-mail com o retorno e um link, para que você possa dar continuidade ao seu cadastro.",
  },
  weAreAlmostThere: {
    title: "Estamos quase lá!",
    description:
      "Infelizmente ainda não atendemos a sua região. Enviaremos notícias da IVI no seu estado para que, em breve, você possa utilizar uma energia renovável e mais barata!",
  },
  unapprovedAccount: {
    title: "Conta não atende os requisitos",
    description:
      "Avaliamos seu perfil de consumo e, infelizmente não será possível dar continuidade ao seu cadastro. Manteremos o seu cadastro em nossa base para futuras oportunidades.",
  },
};

const IVI: Texts = {
  home: {
    title: "A economia começa agora!",
    description:
      "IVI é uma escolha limpa, inteligente e econômica para sua empresa.",
  },
  selectYourDistributor: {
    title: "Não encontrei minha distribuidora",
    description:
      "Basta selecionar a sua distribuidora e manteremos você informado sobre a IVI na sua região!",
  },
  contact: {
    title: "Dados de Contato",
    description:
      "Preencha os seus dados cadastrais para criar sua conta na IVI Energia.",
  },
  validation: {
    title: "",
    description: "",
  },
  holder: {
    title: "Dados do Titular",
    description: "Preencha os dados do titular da conta de luz.",
  },
  holderPF: {
    title: "Dados do Titular",
    description: "Preencha os dados do titular da conta de luz.",
  },
  holderPJ: {
    title: "Dados do Representante Legal",
    description: "Preencha os dados do representante legal.",
  },
  installation: {
    title: "Adicionar imóvel",
    description:
      "Envie o PDF da sua conta de energia mais recente para analisarmos seu perfil de consumo e não se esqueça de adicionar a senha do PDF, caso necessário.",
  },
  confirmYourData: {
    title: "Confirme seus dados",
    description:
      "Verifique os seus dados cadastrais e os dados da conta de energia antes de prosseguir para assinatura do contrato.",
  },
  contractOfAdhesion: {
    title: "Termo de adesão",
    description:
      "Leia o termo de adesão com atenção, faça o upload do seu documento e efetue a assinatura para começar a economizar.",
  },
  pdfErrorValidation: {
    title: "Solicitação de validação enviada!",
    description:
      "Sua fatura será analisada e, em breve, enviaremos um e-mail com o retorno e um link, para que você possa dar continuidade ao seu cadastro.",
  },
  weAreAlmostThere: {
    title: "Estamos quase lá!",
    description:
      "Infelizmente ainda não atendemos a sua região. Enviaremos notícias da IVI no seu estado para que, em breve, você possa utilizar uma energia renovável e mais barata!",
  },
  unapprovedAccount: {
    title: "Conta não atende os requisitos",
    description:
      "Avaliamos seu perfil de consumo e, infelizmente não será possível dar continuidade ao seu cadastro. Manteremos o seu cadastro em nossa base para futuras oportunidades.",
  },
};

const VIVO_SELLER: Texts = {
  home: {
    title: "Gere uma proposta, é rapidinho!",
    description:
      "Preencha os dados do seu cliente para ver se é elegível e fazer a adesão.",
  },
  selectYourDistributor: {
    title: "Não encontrei minha distribuidora",
    description:
      "Basta selecionar a sua distribuidora e manteremos você informado sobre a IVI na sua região!",
  },
  contact: {
    title: "Dados do cliente",
    description:
      "Preencha os dado de contato do seu cliente para que ele receba a proposta.",
  },
  validation: {
    title: "Código de Validação",
    description: "Preencha com o código recebido para validar seu e-mail.",
  },
  holder: {
    title: "Dados do titular da conta",
    description: "Deve ser o mesmo CNPJ ou CPF da conta de luz.",
  },
  holderPF: {
    title: "Dados do titular da conta",
    description: "Preencha os dados do titular da conta de luz.",
  },
  holderPJ: {
    title: "Dados do representante",
    description: "Preencha os dados do representante legal.",
  },
  installation: {
    title: "Dados da conta de luz",
    description:
      "Precisamos de uma fatura dos últimos 3 meses para analisarmos o perfil de consumo.",
  },
  confirmYourData: {
    title: "Confirmação",
    description:
      "Quase lá! Verifique os dados cadastrais e da conta de energia antes de seguir.",
  },
  contractOfAdhesion: {
    title: "Termo de adesão",
    description:
      "Leia o termo de adesão e efetue a assinatura para começar a economizar. IMPORTANTE: esse passo deve ser feito pelo representante legal da empresa.",
  },
  pdfErrorValidation: {
    title: "Solicitação de validação enviada!",
    description:
      "Sua fatura será analisada e, em breve, enviaremos um e-mail com o retorno e um link, para que você possa dar continuidade ao seu cadastro.",
  },
  weAreAlmostThere: {
    title: "Estamos quase lá!",
    description:
      "Infelizmente ainda não atendemos a sua região. Enviaremos notícias da IVI no seu estado para que, em breve, você possa utilizar uma energia renovável e mais barata!",
  },
  unapprovedAccount: {
    title: "Conta não atende os requisitos",
    description:
      "Avaliamos seu perfil de consumo e, infelizmente não será possível dar continuidade ao seu cadastro. Manteremos o seu cadastro em nossa base para futuras oportunidades.",
  },
};

const texts: Record<Partner, Texts> = { VIVO, IVI };
const sellerTexts: Record<"VIVO_SELLER", Texts> = { VIVO_SELLER };

export { texts, sellerTexts };
