import axios, { InternalAxiosRequestConfig } from "axios";
import { usePartnerStore } from "../../partner/use-partner-store";

const { mode } = usePartnerStore.getState();

export const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: { "Content-Type": "application/json" },
});

api.interceptors.request.use((request) => {
  applyDefaultHeaders(request);

  if (mode === "SELLER") 
    applyParceiroEnvironmentHeaders(request);
  else 
    applyClienteEnvironmentHeaders(request);
  return request;
});

const applyDefaultHeaders = (request: InternalAxiosRequestConfig) => {
  if (process.env.REACT_APP_PARCEIRO_ID)
    request.headers["Ivi-Parceiro-Id"] = process.env.REACT_APP_PARCEIRO_ID;
};

const applyClienteEnvironmentHeaders = (
  request: InternalAxiosRequestConfig
) => {
  const token = sessionStorage.getItem("token");
  if (token) {
    request.headers.Authorization = token;
  }
};

const applyParceiroEnvironmentHeaders = (
  request: InternalAxiosRequestConfig
) => {
  const contactEmail = sessionStorage.getItem("contactEmail");
  if (contactEmail) {
    request.headers["Ivi-User-Email"] = contactEmail;
  }
};
