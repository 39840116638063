import { create } from "zustand";

import { Theme, CSSVariables } from "./theme/theme.types";
import { Partner, Mode } from "./partner.types";
import { Assets } from "./assets/assets.types";
import { Texts } from "./texts/texts.types";

import { themes } from "./theme/themes";
import { assets } from "./assets/assets";
import { sellerTexts, texts } from "./texts/texts";

interface PartnerState {
  theme: Theme;
  partner: Partner;
  mode: Mode;
  assets: Assets;
  texts: Texts;
  setPartner: (partner: string) => void;
  setMode: (mode: string) => void;
}

const updateCSSVariables = (variables: CSSVariables) => {
  const root = document.documentElement;
  Object.entries(variables).forEach(([key, value]) => {
    root.style.setProperty(key, value);
  });
};

const updateFavicon = (favicon: string) => {
  const link = document.querySelector("link[rel*='icon']") as HTMLLinkElement;
  link.href = favicon;
};

const PARTNERS: Record<string, Partner> = {
  [process.env.REACT_APP_PARCEIRO_IVI_ID as string]: "IVI",
  [process.env.REACT_APP_PARCEIRO_VIVO_ID as string]: "VIVO",
};

const PARTNER_ID = process.env.REACT_APP_PARCEIRO_ID as string;
const MODE = process.env.REACT_APP_ENVIRONMENT as string;
const PARTNER = PARTNERS[PARTNER_ID];

export const usePartnerStore = create<PartnerState>((set) => ({
  theme: themes[PARTNER],
  assets: assets[PARTNER],
  partner: PARTNER,
  mode: MODE === "PARCEIRO" && PARTNER === "VIVO" ? "SELLER" : "CUSTOMER",
  texts: MODE === "PARCEIRO" && PARTNER === "VIVO" ? sellerTexts.VIVO_SELLER : texts[PARTNER],

  setPartner: (partnerId) => {
    const partner = PARTNERS[partnerId];
    const theme = themes[partner];
    const asset = assets[partner];

    updateCSSVariables(theme.cssVariables);
    updateFavicon(asset.favicon);

    set({ theme: theme, partner: partner, assets: asset });
  },

  setMode: (mode) => {
    if (mode === "PARCEIRO" && PARTNER === "VIVO") {
      set({ mode: "SELLER", texts: sellerTexts.VIVO_SELLER });
      return;
    }

    set({ mode: "CUSTOMER", texts: texts[PARTNER] });
  },
}));
