import { Assets } from "./assets.types";
import { Partner } from "../partner.types";

import faviconIVI from "../../assets/favicon-ivi.png"
import logoIVI from "../../assets/logo.svg";
import confirmYourData from "../../assets/confirmYourData/confirmYourData.svg";
import contact from "../../assets/contact/contact.svg";
import contractOfAdhesion from "../../assets/contractOfAdhesion/contractOfAdhesion.svg";
import contaDeLuz from "../../assets/holder/conta-de-luz.svg";
import home from "../../assets/home/home.svg";
import installation from "../../assets/installation/installation.svg";
import selectYourDistributor from "../../assets/selectYourDistributor/selectYourDistributor.svg";
import validation from "../../assets/validation/validation.svg";

import faviconVIVO from "../../assets/favicon-vivo.png";
import logoVIVO from "../../assets/fotos-vivo/logoenergiavpng.png";
import homeVivo from "../../assets/fotos-vivo/sun-energy-icon.svg";
import contactVivo from "../../assets/fotos-vivo/sun-icon.svg";
import validationVivo from "../../assets/fotos-vivo/sun-icon.svg";
import contaDeLuzVivo from "../../assets/fotos-vivo/building-icon.svg";
import installationVivo from "../../assets/fotos-vivo/lamp-icon.svg";
import confirmYourDataVivo from "../../assets/fotos-vivo/lamp-icon.svg";
import contractOfAdhesionVivo from "../../assets/fotos-vivo/lamp-icon.svg";

import checkIcon from "../../assets/pdfErrorValidation/check.png";
import thunder from "../../assets/UnapprovedAccount/flashEnergy.svg";

const VIVO: Assets = {
  favicon: faviconVIVO,
  logo: {
    image: logoVIVO,
    alt: "Logo da VIVO",
  },
  home: {
    image: homeVivo,
    alt: "Home",
  },
  selectYourDistributor: {
    image: homeVivo,
    alt: "Selecione sua distribuidora",
  },
  contact: {
    image: contactVivo,
    alt: "Contato",
  },
  validation: {
    image: validationVivo,
    alt: "Validação",
  },
  holder: {
    image: contaDeLuzVivo,
    alt: "Conta de luz",
  },
  holderPF: {
    image: contaDeLuzVivo,
    alt: "Conta de luz",
  },
  holderPJ: {
    image: contaDeLuzVivo,
    alt: "Conta de luz",
  },
  installation: {
    image: installationVivo,
    alt: "Instalação",
  },
  confirmYourData: {
    image: confirmYourDataVivo,
    alt: "Confirme seus dados",
  },
  contractOfAdhesion: {
    image: contractOfAdhesionVivo,
    alt: "Contrato de adesão",
  },
  pdfErrorValidation: {
    image: checkIcon,
    alt: "Check icon",
  },
  weAreAlmostThere: {
    image: checkIcon,
    alt: "Check icon",
  },
  unapprovedAccount: {
    image: thunder,
    alt: "Thunder icon",
  },
};

const IVI: Assets = {
  favicon: faviconIVI,
  logo: {
    image: logoIVI,
    alt: "Logo da IVI",
  },
  confirmYourData: {
    image: confirmYourData,
    alt: "Confirme seus dados",
  },
  contact: {
    image: contact,
    alt: "Contato",
  },
  contractOfAdhesion: {
    image: contractOfAdhesion,
    alt: "Contrato de adesão",
  },
  holder: {
    image: contaDeLuz,
    alt: "Conta de luz",
  },
  holderPF: {
    image: contaDeLuz,
    alt: "Conta de luz",
  },
  holderPJ: {
    image: contaDeLuz,
    alt: "Conta de luz",
  },
  home: {
    image: home,
    alt: "Home",
  },
  installation: {
    image: installation,
    alt: "Instalação",
  },
  selectYourDistributor: {
    image: selectYourDistributor,
    alt: "Selecione sua distribuidora",
  },
  validation: {
    image: validation,
    alt: "Validação",
  },
  pdfErrorValidation: {
    image: checkIcon,
    alt: "Check icon",
  },
  weAreAlmostThere: {
    image: checkIcon,
    alt: "Check icon",
  },
  unapprovedAccount: {
    image: thunder,
    alt: "Thunder icon",
  },
};

const assets: Record<Partner, Assets> = { VIVO, IVI };

export { assets };
