import "./SecondaryScreen.css";

import React, { ReactNode, useRef } from "react";
import { useNavigate } from "react-router-dom";

import logoIVI from "../assets/logo.svg";
import { usePartnerStore } from "../partner/use-partner-store";
import { FaqModalRef } from "./FaqModal";

interface SecondaryScreenProps {
  logoSrc: string;
  iconOne: string;
  iconTwo: string;
  children?: ReactNode;
  onclick?: () => void;
}

const SecondaryScreen: React.FC<SecondaryScreenProps> = ({
  logoSrc,
  iconOne,
  children,
}) => {
  const { assets, partner } = usePartnerStore();
  const navigate = useNavigate();
  const faqModal = useRef<FaqModalRef>(null);

  return (
    <div className="my-component">
      <div className="top-section">
        <img
          src={assets.logo.image}
          alt="Logo EnergiaV Energia"
          className="logo-energiav-new"
          onClick={() => navigate("/")}
        />

        {partner === "VIVO" && (
          <img
            src={logoIVI}
            alt="Logo EnergiaV Energia"
            className="logo-energiav-new"
          />
        )}

        {partner === "IVI" && (
          <button onClick={() => faqModal.current?.open()}>
            <img
              src={iconOne}
              alt="Icon-One"
              className="question-lado-direito"
            />
          </button>
        )}
      </div>

      <div className="middle-section">{children}</div>

      {/* <img
        src={seloIVI}
        alt="A energia solar por assinatura é oferecida em parceria com a IVI Energia."
        className="secondary-screen-selo-ivi"
      /> */}

      {/* <SeloIVI className="secondary-screen-selo-ivi" /> */}
    </div>
  );
};

export default SecondaryScreen;
