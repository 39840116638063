//@ts-ignore
import { BlipChat } from "blip-chat-widget";
import { useEffect } from "react";

const BlipChatWidget = () => {
  useEffect(() => {
    const customStyle = `
      #blip-chat-open-iframe {
        bottom: 20px !important;
        right: 30px !important;
      }
    `;

    const blipClient = new BlipChat()
      .withAppKey(
        "aXZpZW5lcmdpYXJvdXRlcjo4ZWVhMmIyMy04MjNjLTQwMTAtYmEzMS1kOWIxNTA0MDkzNTU="
      )
      .withButton({ color: "#0096fa", icon: "" })
      .withCustomStyle(customStyle)
      .withCustomCommonUrl("https://ivienergia.chat.blip.ai/");

    blipClient.build();

    return () => {
      blipClient.destroy();
    };
  }, []);

  return <></>;
};

export { BlipChatWidget };
