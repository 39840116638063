const MAPPED_ROUTES = {
  "/confirm-your-data": "confirmYourData",
  "/contact": "contact",
  "/contract-of-adhesion": "contractOfAdhesion",
  "/": "home",
  "/installation": "installation",
  "/select-your-distributor": "selectYourDistributor",
  "/validation": "validation",
  "/holder": "holder",
  "/create-holder": "holderPF",
  "/create-holder-cnpj": "holderPJ",
  "/pdf-error-validation": "pdfErrorValidation",
  "/we-are-almost-there": "weAreAlmostThere",
  "/unapproved-account": "unapprovedAccount",
} as const;

export { MAPPED_ROUTES };
