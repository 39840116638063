import React, { useEffect } from "react";
import { usePartnerStore } from "./use-partner-store";

type PartnerProviderProps = React.PropsWithChildren<{}>;

const PartnerProvider = ({ children }: PartnerProviderProps) => {
  const { setPartner, setMode } = usePartnerStore();

  useEffect(() => {
    const partner = process.env.REACT_APP_PARCEIRO_ID as string;
    const mode = process.env.REACT_APP_ENVIRONMENT as string;

    if (partner) setPartner(partner);
    if (mode) setMode(mode);
  }, [setPartner, setMode]);

  return <>{children}</>;
};

export { PartnerProvider };
