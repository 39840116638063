import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logoIvi from "../../assets/logo.svg";
import question from "../../assets/question.svg";
import comentIcon from "../../assets/comentIcon.svg";
import "./SelectYourDistributor.css";
import LeftHomeComponent from "../../components/leftHomeComponent/LeftHomeComponent";
import RightHomeComponent from "../../components/RightHomeComponent/RightHomeComponent";
import MainHomeComponent from "../../components/MainHomeComponent/MainHomeComponent";
import CustomSelectComponent from "../../components/CustomSelectComponent";
import ButtonComponent from "../../components/ButtonComponent";
import ListDistribuidoras from "../../utils/listDistribuidora.json";
import { Input } from "@nextui-org/react";
import { useMutation } from "@tanstack/react-query";
import { distNotFound } from "../../services/onboarding";
import { POST_DIST_NOT_FOUND } from "../../query/constants";
import LoadingFullPage from "../../components/Loading/LoadingFullPage/LoadingFullPage";
import { getImage } from "../../services/images";
import { textsPath } from "../../services/texts";
import { isValidEmail } from "../../services/validations";
import { usePartnerStore } from "../../partner/use-partner-store";

export const SelectYourDistributor = () => {
  const navigate = useNavigate();

  const [selectedDistributor, setSelectedDistributor] = useState(null);
  const [email, setEmail] = useState<string>("");
  const [isEmailInvalid, setIsEmailInvalid] = useState<boolean | null>(null);

  const postDistNotFound = useMutation({
    mutationFn: distNotFound,
    mutationKey: [POST_DIST_NOT_FOUND],
    onSuccess: (data) => {
      // console.log(data);
      navigate("/we-are-almost-there");
    },
    onError: (erro) => {
      console.log(erro);
    },
  });

  const handleSelectChange = (selectedOption: any) => {
    setSelectedDistributor(selectedOption.value);
  };

  const handleEmailChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    const value = target.value;
    setEmail(value);

    const isValid = isValidEmail(value);
    setIsEmailInvalid(!isValid);
  };

  const handleBackButtonClick = () => {
    navigate("/");
  };

  const handleButtonClick = () => {
    if (selectedDistributor !== null && !isEmailInvalid) {
      postDistNotFound.mutate({
        Email: email,
        Distribuidora: selectedDistributor,
      });
    }
  };

  const { assets, texts } = usePartnerStore();

  return (
    <MainHomeComponent>
      <LeftHomeComponent
        logoSrc={logoIvi}
        title={texts.selectYourDistributor.title}
        description={texts.selectYourDistributor.description}
        imgSrc={assets.selectYourDistributor.image}
      />
      <RightHomeComponent
        questionSrc={question}
        commentIconSrc={comentIcon}
        cardHomeClass="container-distributor"
        title={texts.selectYourDistributor.title}
        description={texts.selectYourDistributor.description}
      >
        <div className="container-inputs-distribuitor">
          <CustomSelectComponent
            options={ListDistribuidoras}
            // options={options}
            placeholder="Selecione a sua distribuidora"
            onSelect={handleSelectChange}
            customFontSize="14px"
            customWidth="280px"
          />

          <div className="teste z-0">
            <Input
              type="text"
              size="md"
              label="E-mail"
              value={email}
              onChange={handleEmailChange}
              color="primary"
              classNames={{
                input: ["text-noto"],
              }}
            />
            {isEmailInvalid && (
              <p className="email-distributor-error-input">
                Por favor, preencha com um e-mail válido
              </p>
            )}
          </div>
        </div>

        <div className="button-container-distributor space">
          <ButtonComponent
            className="back-button"
            onClick={handleBackButtonClick}
          >
            Voltar
          </ButtonComponent>
          <ButtonComponent
            className="continue-button"
            onClick={handleButtonClick}
            isDisabled={
              selectedDistributor === null ||
              isEmailInvalid === true ||
              isEmailInvalid === null
            }
          >
            Solicitar
          </ButtonComponent>
        </div>
        {postDistNotFound.isPending && <LoadingFullPage />}
      </RightHomeComponent>
    </MainHomeComponent>
  );
};
