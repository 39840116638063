import SecondaryScreen from "../../components/SecondaryScreen";
import question from "../../assets/question.svg";
import comentIcon from "../../assets/comentIcon.svg";
import flash from "../../assets/UnapprovedAccount/flashEnergy.svg";
import ButtonComponent from "../../components/ButtonComponent";
import "./UnapprovedAccount.css";
import { useNavigate } from "react-router";
import logoIvi from "../../assets/fotos-vivo/logo-Ivi.svg";
import { usePartnerStore } from "../../partner/use-partner-store";

export const UnapprovedAccount = () => {
  const { assets, texts } = usePartnerStore();
  const navigate = useNavigate();
  return (
    <SecondaryScreen logoSrc={logoIvi} iconOne={question} iconTwo={comentIcon}>
      <section className="container-unapproved-account">
        <img
          src={assets.unapprovedAccount.image}
          alt={assets.unapprovedAccount.alt}
          style={{ width: "64px" }}
        />
        <h1 className="title-unapproved-account">
          {texts.unapprovedAccount.title}
        </h1>
        <p className="description-unapproved-account">
          {texts.unapprovedAccount.description}
        </p>
        <ButtonComponent
          className="continue-button"
          // className="btn-homee"
          style={{ margin: "20px 0", width: "190px", padding: "0 24px" }}
          onClick={() => navigate("/installation")}
        >
          Incluir outra conta
        </ButtonComponent>
      </section>
    </SecondaryScreen>
  );
};
