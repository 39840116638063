import "../RegistrationCompleted.css";

import { useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import logoIvi from "../../../assets/logo.svg";
import arrowRight from "../../../assets/arrow-rigth.svg";
import question from "../../../assets/question.svg";
import comentIcon from "../../../assets/comentIcon.svg";
import awaitingValidation from "../../../assets/registrationCompleted/awaiting-validation.svg";
import piggyBank from "../../../assets/registrationCompleted/piggy-bank.svg";
import solarPanel from "../../../assets/registrationCompleted/solar-panel.svg";
import SecondaryScreen from "../../../components/SecondaryScreen";
import ButtonComponent from "../../../components/ButtonComponent";
import CardContainer from "../../../components/card/cardFinish/CardContainer";
import Card from "../../../components/card/cardFinish/Card";
import { removeItemStorage } from "../../../utils/localStorage";
import { SessionContext } from "../../../context/session";
import { FaqModal, FaqModalRef } from "../../../components/FaqModal";

const IVICustomerRegistrationCompleted = () => {
  const { clearStorage } = useContext(SessionContext);
  const navigate = useNavigate();

  const faqModal = useRef<FaqModalRef | null>(null);

  useEffect(() => {
    removeItemStorage("cnpj");
    removeItemStorage("cpf");
    removeItemStorage("holderData");
    removeItemStorage("holderCNPJ");
    removeItemStorage("contact");
    removeItemStorage("arquivo");
    removeItemStorage("distribuidora");
    sessionStorage.clear();
  }, []);

  const handleButtonClick = () => {
    clearStorage();
    navigate("/");
  };
  return (
    <div className="registration-completed-container">
      <header className="registration-completed-header">
        <img
          src={logoIvi}
          alt="Logo EnergiaV Energia"
          className="logo-energiav-new"
        />
        <button onClick={() => faqModal.current?.open()}>
          <img
            src={question}
            alt="Icon-One"
            className="question-lado-direito"
          />
        </button>
        <FaqModal ref={faqModal} />
      </header>

      <main className="middle-content-registration-complet">
        <h1 className="title-registration-complet">Cadastro finalizado!</h1>
        <div className="description-registration-complet">
          <p>
            Estamos muito felizes com a sua decisão em utilizar energia
            renovável!
          </p>
          <p>
            Seus dados serão analisados e, em breve, você receberá um e-mail com
            o <strong>termo de adesão</strong> e informações sobre os{" "}
            <strong>próximos passos.</strong>
          </p>
          <p>
            Estamos juntos nessa missão de fazer mais pelo planeta e contribuir
            de verdade para um modelo de consumo mais sustentável!
          </p>
        </div>

        <CardContainer>
          <Card
            imgSrc={awaitingValidation}
            altText="Imagem aguardando validação"
            cardText={`Aguardando <br/> validação do cadastro`}
          />

          <img src={arrowRight} className="arrow" alt="Seta para a direita" />

          <Card
            imgSrc={solarPanel}
            altText="Imagem solar painel"
            cardText={`Aguardando <br/> benefícios`}
            customClass="opacity"
          />
          <img src={arrowRight} className="arrow" alt="Seta para a direita" />
          <Card
            imgSrc={piggyBank}
            altText="Imagem porco cofre"
            cardText={`Economizando <br/> com a IVI`}
            customClass="opacity"
          />
        </CardContainer>

        <ButtonComponent
          className="continue-button"
          onClick={handleButtonClick}
          style={{ width: "217px", margin: "20px 0" }}
        >
          Cadastrar nova instalação
        </ButtonComponent>
      </main>
    </div>
  );
};

export { IVICustomerRegistrationCompleted };
