import { Theme } from "./theme.types";
import { Partner } from "../partner.types";

const VIVO: Theme = {
  colors: {
    primary: "#FF0000",
    secondary: "#00FF00",
    background: "#FFFFFF",
    text: "#000000",
  },
  cssVariables: {
    "--main-color": "#660099",
    "--continue-button-font": "#FFFFFF",
    "--back-button-color": "#660099",
    "--secondary-color": "#660099",
  },
};

const IVI: Theme = {
  colors: {
    primary: "#0000FF",
    secondary: "#FF00FF",
    background: "#F0F0F0",
    text: "#333333",
  },
  cssVariables: {
    "--main-color": "#dfff02",
    "--continue-button-font": "#212121",
    "--back-button-color": "#212121",
    "--secondary-color": "#d8ef46",
  },
};

const themes: Record<Partner, Theme> = { VIVO, IVI };

export { themes };
