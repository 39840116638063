import { usePartnerStore } from "../../partner/use-partner-store";
import { VivoCustomerRegistrationCompleted } from "./Versions/VivoCustomerRegistrationCompleted";
import { VivoSellerRegistrationCompleted } from "./Versions/VivoSellerRegistrationCompleted";
import { IVICustomerRegistrationCompleted } from "./Versions/IVICustomerRegistrationCompleted";

import { Partner, Mode } from "../../partner/partner.types";

const RegistrationCompleted = () => {
  
  const { mode, partner } = usePartnerStore();

  const versions: Record<Partner, Record<Mode, JSX.Element | null>> = {
    VIVO: {
      SELLER: <VivoSellerRegistrationCompleted />,
      CUSTOMER: <VivoCustomerRegistrationCompleted />,
    },
    IVI: {
      CUSTOMER: <IVICustomerRegistrationCompleted />,
      SELLER: null,
    },
  };

  if (!versions[partner]) return null;

  return versions[partner][mode];
};

export default RegistrationCompleted;
