import React, { ReactNode, useState, createContext, useEffect } from "react";
import { getItemStorage, setItemStorage } from "../utils/localStorage";
import { SESSION_KEYS } from "../utils/constants";

interface SessionContextType {
  getItemSession: (key: string) => string | null;
  setItemSession: (key: string, value: string) => void;
  clearStorage: () => void;
  removeItemSession: (key: string) => void;
}

const initialSessionContext: SessionContextType = {
  getItemSession: () => null,
  setItemSession: () => {},
  clearStorage: () => {},
  removeItemSession: () => {},
};

export const SessionContext = createContext<SessionContextType>(
  initialSessionContext
);

interface SessionProviderProps {
  children: ReactNode;
}

export function SessionStorageStorage({ children }: SessionProviderProps) {
  const [storage, setStorage] = useState<Record<string, string>>({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (Object.keys(storage).length !== 0) return;

    async function populateStorage() {
      for (const key of SESSION_KEYS) {
        const content = await getItemStorage(key);

        if (content) {
          setStorage((prevStorage) => ({ ...prevStorage, [key]: content }));
        }
      }

      setLoading(false);
    }

    populateStorage();
  }, [storage]);

  function setItemSession(key: string, value: string) {
    setItemStorage(key, value);
    setStorage((prevStorage) => ({ ...prevStorage, [key]: value }));
  }

  function clearStorage() {
    setStorage({});
  }

  function removeItemSession(key: string) {
    setItemStorage(key, "");
    setStorage((prevStorage) => {
      const newStorage = { ...prevStorage };
      delete newStorage[key];
      return newStorage;
    });
  }

  function getItemSession(key: string): string | null {
    return storage[key] as string | null;
  }

  if (loading) return <></>;

  return (
    <SessionContext.Provider
      value={{
        getItemSession,
        setItemSession,
        clearStorage,
        removeItemSession,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
}
