import "./WeAreAlmostThere.css";
import React from "react";

import logoIvi from "../../../assets/fotos-vivo/logo-Ivi.svg";
import question from "../../../assets/question.svg";
import comentIcon from "../../../assets/comentIcon.svg";
import SecondaryScreen from "../../../components/SecondaryScreen";
import ButtonComponent from "../../../components/ButtonComponent";
import { navigateToLp } from "../../../utils/landingPage";
import { usePartnerStore } from "../../../partner/use-partner-store";

interface MyComponentProps {}

const WeAreAlmostThere: React.FC<MyComponentProps> = () => {
  const { assets, texts } = usePartnerStore();
  return (
    <SecondaryScreen logoSrc={logoIvi} iconOne={question} iconTwo={comentIcon}>
      <div className="middle-content">
        <img
          src={assets.weAreAlmostThere.image}
          alt={assets.weAreAlmostThere.alt}
        />
        <br />
        <div>
          <h1 className="title-almost-there">{texts.weAreAlmostThere.title}</h1>
          <p className="description-almost-there">
            {texts.weAreAlmostThere.description} <br />
            Siga a IVI no Instagram e fique por dentro das novidades!
          </p>
        </div>
        <ButtonComponent
          className="continue-button btn-back-almost-there"
          style={{ width: "192px", margin: "20px 0", padding: "20px 0" }}
          onClick={navigateToLp}
        >
          Voltar para tela Inicial
        </ButtonComponent>
      </div>
    </SecondaryScreen>
  );
};

export default WeAreAlmostThere;
