import "../RegistrationCompleted.css";

import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import arrowRight from "../../../assets/arrow-rigth.svg";
import ButtonComponent from "../../../components/ButtonComponent";
import CardContainer from "../../../components/card/cardFinish/CardContainer";
import Card from "../../../components/card/cardFinish/Card";
import { removeItemStorage } from "../../../utils/localStorage";
import { SessionContext } from "../../../context/session";

import logoIvi from "../../../assets/fotos-vivo/logo-Ivi.svg";
import logoEnergiaV from "../../../assets/fotos-vivo/logo-energiav.svg";
import thunderIcon from "../../../assets/fotos-vivo/thunder-icon.svg";

import savingsIcon from "../../../assets/fotos-vivo/savings-icon.svg";
import registeredDocumentIcon from "../../../assets/fotos-vivo/registered-document-icon.svg";
import solarPanelIcon from "../../../assets/fotos-vivo/solar-panel-icon.svg";
import { navigateToLp } from "../../../utils/landingPage";
import { usePartnerStore } from "../../../partner/use-partner-store";

const VivoSellerRegistrationCompleted = () => {
  const { mode } = usePartnerStore();

  const { clearStorage } = useContext(SessionContext);
  const navigate = useNavigate();

  useEffect(() => {
    removeItemStorage("cnpj");
    removeItemStorage("cpf");
    removeItemStorage("holderData");
    removeItemStorage("holderCNPJ");
    removeItemStorage("contact");
    removeItemStorage("arquivo");
    removeItemStorage("distribuidora");
    sessionStorage.clear();
  }, []);

  const handleButtonClick = () => {
    clearStorage();
    if (mode === "SELLER") navigate("/");
    else navigateToLp();
  };
  return (
    <div className="registration-completed-container">
      <header className="registration-completed-header">
        <img
          src={logoEnergiaV}
          alt="Logo EnergiaV Energia"
          className="logo-energiav-new"
        />
        <img src={logoIvi} alt="Logo Vivo Auren" className="logo-Ivi" />
      </header>

      <main className="middle-content-registration-complet">
        <img src={thunderIcon} alt="Icone de raio" className="thunder-icon" />
        <h1 className="title-registration-complet">Cadastro finalizado!</h1>
        <div className="description-registration-complet">
          <p style={{ fontWeight: "bold" }}>
            <strong style={{ color: "var(--main-color)" }}>
              Seu cliente receberá um e-mail com o termo de adesão para
              assinatura e informações sobre os próximos passos.
            </strong>
          </p>
          <p>
            Fique tranquilo, o parceiro de vendas é copiado na comunicação com o
            cliente!
          </p>
        </div>

        <CardContainer>
          <Card
            imgSrc={registeredDocumentIcon}
            altText="Imagem aguardando validação"
            cardText="Aguardando <br /> validação de cadastro"
          />
          <img src={arrowRight} className="arrow" alt="Seta para a direita" />
          <Card
            imgSrc={solarPanelIcon}
            altText="imagem de painel solar"
            cardText=" Aguardando <br /> benefícios"
            customClass="opacity"
          />
          <img src={arrowRight} className="arrow" alt="Seta para a direita" />
          <Card
            imgSrc={savingsIcon}
            altText="Imagem porco cofre"
            cardText="Economizando <br /> com a IVI"
            customClass="opacity"
          />
        </CardContainer>

        <ButtonComponent
          className="continue-button"
          onClick={handleButtonClick}
          style={{ width: "217px", margin: "20px 0" }}
        >
          Entendi
        </ButtonComponent>
      </main>
    </div>
  );
};

export { VivoSellerRegistrationCompleted };
