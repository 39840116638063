import "./LeftHomeComponent.css";

import React, { useEffect, useRef, useState } from "react";
import logoIvi from "../../assets/fotos-vivo/logo-Ivi.svg";
import { ModalTermosDeUso } from "../modal/ModalTermsUso/ModelTermosDeUso";
import { ModalRef } from "../../utils/modal";
import Calculator from "../calculator/calculator";
import { usePartnerStore } from "../../partner/use-partner-store";
import { useLocation } from "react-router-dom";
import { MAPPED_ROUTES } from "../../partner/routes/routes";
import { RoutePath } from "../../partner/routes/routes.types";
// import { navigateToLp } from "../../utils/landingPage";

interface LeftHomeProps {
  logoSrc: string;
  title: string;
  description: string;
  imgSrc?: string;
  showCalculator?: boolean;
}

const LeftHomeComponent: React.FC<LeftHomeProps> = ({
  logoSrc,
  title,
  description,
  imgSrc,
  showCalculator: show,
}) => {
  const modalTermosDeUsoRef = useRef<ModalRef | null>(null);

  const { partner, assets, mode } = usePartnerStore();

  const { pathname } = useLocation();
  const [prevPathname, setPrevPathname] = useState<string>("");

  const [leftSectionContent, setLeftSectionContent] = useState<{
    image?: string;
    alt?: string;
  }>({
    image: "",
    alt: "",
  });

  if (prevPathname !== pathname) {
    setPrevPathname(pathname);

    if (mode === "SELLER" && pathname === "/distribuidora") {
      setLeftSectionContent({
        image: assets.home.image,
        alt: assets.home.alt,
      });
    } else if (mode === "SELLER" && pathname === "/") {
      setLeftSectionContent({
        image: "",
        alt: "",
      });
    } else {
      const routeName = MAPPED_ROUTES[pathname as RoutePath];
      setLeftSectionContent({
        image: assets[routeName].image,
        alt: assets[routeName].alt,
      });
    }
  }

  // console.log("leftSectionContent", leftSectionContent);

  // useEffect(() => {
  //   console.log("rodou");
  //   const routeName = MAPPED_ROUTES[pathname as RoutePath];

  //   if (mode === "SELLER") {
  //     // Quando o usuário é um vendedor, a rota inicial é a tela da calculadora
  //     if (pathname === "/distribuidora") {
  //       setLeftSectionContent({
  //         title: texts.home.title,
  //         description: texts.home.description,
  //         image: assets.home.image,
  //         alt: assets.home.alt,
  //       });

  //       return;
  //     }
  //     if (pathname === "/") {
  //       setLeftSectionContent({
  //         title: "",
  //         description: "",
  //         image: "",
  //         alt: "",
  //       });

  //       return;
  //     }
  //   }

  //   setLeftSectionContent({
  //     title: texts[routeName].title,
  //     description: texts[routeName].description,
  //     image: assets[routeName].image,
  //     alt: assets[routeName].alt,
  //   });
  // }, [setLeftSectionContent, texts, assets, mode, pathname]);

  const showCalculator = mode === "SELLER" && pathname === "/";

  return (
    <div className="left-home">
      <div className="container-logo-title-description-left-home">
        <div className="left-home-header">
          <img
            src={assets.logo.image}
            alt={assets.logo.alt}
            className="logo-energiav-new"
          />

          <img
            src={logoIvi}
            alt="Logo Vivo e Auren"
            data-theme={partner}
            className={`logo-Ivi ${partner === "IVI" ? "hidden" : ""}`}
          />
        </div>
      </div>

      <div className="left-home-content">
        <h3 className="left-home-content-title">{title}</h3>
        <span className="left-home-content-description">
          {description.split("\n").map((d) => (
            <p key={d}>{d}</p>
          ))}
        </span>

        {leftSectionContent.image && !showCalculator && (
          <div className="left-home-content-icon-container">
            <img
              src={leftSectionContent.image}
              alt={leftSectionContent.alt}
              className="left-home-content-icon"
            />
          </div>
        )}

        {showCalculator && (
          <div className="left-home-calculator-container">
            <Calculator />
          </div>
        )}
      </div>

      <ModalTermosDeUso ref={modalTermosDeUsoRef} />

      <div className="left-home-footer">
        <p
          className="termo-adesao"
          onClick={() => modalTermosDeUsoRef.current?.open()}
        >
          Termos de Uso e Polticia de Privacidade
        </p>
      </div>
    </div>
  );
};

export default LeftHomeComponent;
