import { useEffect } from "react";

const GoogleAnalytics = ({ gaId }: { gaId: string }) => {
  useEffect(() => {
    if (!gaId) return;

    const script = document.createElement("script");
    script.src = `https://www.googletagmanager.com/gtag/js?id=${gaId}`;
    script.async = true;
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag(...args: any[]) {
      window.dataLayer.push(args);
    }
    gtag("js", new Date());

    gtag("config", "G-WYQF4SN1PK");

    return () => {
      document.head.removeChild(script);
    };
  }, [gaId]);

  return null;
};

export { GoogleAnalytics };
