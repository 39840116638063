import ButtonComponent from "../../components/ButtonComponent";
import logoIvi from "../../assets/fotos-vivo/logo-Ivi.svg";

import { useNavigate } from "react-router-dom";
import SecondaryScreen from "../../components/SecondaryScreen";
import question from "../../assets/question.svg";
import comentIcon from "../../assets/comentIcon.svg";
import "./pdfErrorValidation.css";
import { usePartnerStore } from "../../partner/use-partner-store";

function PdfErrorValidation() {
  const { assets, texts } = usePartnerStore();
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/");
  };
  return (
    <SecondaryScreen logoSrc={logoIvi} iconOne={question} iconTwo={comentIcon}>
      <div className="middle-content-pdf-error">
        <img
          src={assets.pdfErrorValidation.image}
          alt={assets.pdfErrorValidation.alt}
        />
        <h1 className="title-pdf-error-validation">
          {texts.pdfErrorValidation.title}
        </h1>
        <p className="text-pdf-error">{texts.pdfErrorValidation.description}</p>
        <ButtonComponent
          className="continue-button"
          style={{ width: "192px", margin: "20px auto", letterSpacing: "1px" }}
          //  className="btn-homee"
          onClick={handleButtonClick}
        >
          Voltar para tela inicial
        </ButtonComponent>
      </div>
    </SecondaryScreen>
  );
}

export default PdfErrorValidation;
