import React, { ReactNode, useRef } from "react";
import "./RightHomeComponent.css";
import { FaqModal, FaqModalRef } from "../FaqModal";
import { ModalTermosDeUso } from "../modal/ModalTermsUso/ModelTermosDeUso";
import { ModalRef } from "../../utils/modal";
import logoEnergiaV from "../../assets/fotos-vivo/logo-energiav.svg";
import logoIvi from "../../assets/fotos-vivo/logo-Ivi.svg";
import SeloIVI from "../SeloIVI/SeloIVI";
import { usePartnerStore } from "../../partner/use-partner-store";

interface RightHomeProps {
  questionSrc: string;
  commentIconSrc: string;
  cardHomeClass?: string; // Nova propriedade opcional
  children?: ReactNode;
  title?: string;
  description?: string;
}

const RightHomeComponent: React.FC<RightHomeProps> = ({
  questionSrc,
  title,
  description,
  cardHomeClass = "card-home", // Use 'card-home' por padrão se não for fornecido
  children,
}) => {
  const { assets, partner } = usePartnerStore();
  const faqModalRef = useRef<FaqModalRef | null>(null);
  const modalTermosDeUsoRef = useRef<ModalRef | null>(null);
  // teste

  const openFaq = () => {
    faqModalRef.current?.open();
  };

  const openTermosDeUso = () => {
    modalTermosDeUsoRef.current?.open();
  };

  return (
    <div className="right-home">
      <div className="header-mobile">
        <img
          src={assets.logo.image}
          alt={assets.logo.alt}
          className="logo-energiav"
        />
        {partner === "VIVO" && (
          <img src={logoIvi} alt="Logo Vivo e Auren" className="logo-Ivi" />
        )}
        {partner === "IVI" && (
          <img
            src={questionSrc}
            alt="question-esquerda"
            onClick={openFaq}
            className="question-lado-direito"
          />
        )}
      </div>

      <div className="header-desktop">
        <img
          src={questionSrc}
          alt="question-esquerda"
          onClick={openFaq}
          className="question-lado-direito"
        />
      </div>

      <div className={cardHomeClass}>
        <div className="container-title-description-mobile-version">
          <h3 className="h3-custom-mobile">{title}</h3>
          <p className="description-mobile-right">{description}</p>
        </div>
        {children}
      </div>

      <div className="container-termo-adesao-mobile">
        {partner === "VIVO" && <SeloIVI />}
        <p className="termo-adesao-mobile" onClick={() => openTermosDeUso()}>
          Termos de Uso e Polticia de Privacidade
        </p>
      </div>
      <FaqModal ref={faqModalRef} />
      <ModalTermosDeUso ref={modalTermosDeUsoRef} />
    </div>
  );
};

export default RightHomeComponent;
