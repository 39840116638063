import { useEffect } from "react";

declare global {
  interface Window {
    dataLayer: any[];
  }
}

const GoogleTagManager = ({ gtmId }: { gtmId: string }) => {
  useEffect(() => {
    if (!gtmId) return;
    
    (function (w: Window & { [key: string]: any }, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s) as HTMLScriptElement,
        dl = l != "dataLayer" ? "&l=" + l : "";
      j.async = true;
      j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
      if (f.parentNode) {
        f.parentNode.insertBefore(j, f);
      }
    })(window, document, "script", "dataLayer", gtmId);
  }, [gtmId]);

  return null;
};

export { GoogleTagManager };
